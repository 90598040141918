.loader {
  width: 36px;
  height: 36px;
  border: 3px solid #F2F2F2;
  border-bottom-color: #69D393;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 